import { format } from "date-fns";
import { decode } from "jsonwebtoken";
import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { Navigate } from "react-router";

export const AuthGuard = ({
  children,
  roles,
}: {
  children: JSX.Element;
  roles: string[];
}) => {
  const { user } = useAuth();
  if (!user?.access_token) {
    return <Navigate to={"/login"} />;
  }

  // const decoded = decode(user?.access_token) as IToken;
  const subRoles = user.profile.groups;
  

  console.count(`Render  AuthGuard ${subRoles.join(",")}`);
  // const subRoles = decoded["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"]
  const hasRole = subRoles.some((role) => roles.includes(role));

  if (!hasRole) {
    return <Navigate to={"/"} />;
  }
  return children;
};


