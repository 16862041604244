import { Card, CardMedia, Stack } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { FaMicrosoft } from "react-icons/fa";
import { useAuth } from "react-oidc-context";
import DebouncedButton from "../components/DebounceButton";
import Dark from "../assets/logo-dark.svg";
import Light from "../assets/logo-white.svg";
import useDarkMode from "@src/hooks/useDarkMode";

const useStyles = makeStyles()((theme: any) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  media: {
    paddingTop: "30%", // 16:9,
  },
  card: {
    margin: "0 auto",
    padding: 20,
    minWidth: 300,
    width: 350,
  },
  cardItem: {
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    gap: 15,
    marginTop: 10,
    width: "100%",
    paddingTop: 60,
    paddingBottom: 40,
  },
}));

const Login = () => {
  const { classes } = useStyles();
  const theme = useTheme<any>();
  const { isDarkMode } = useDarkMode();
  const { signinRedirect, isLoading } = useAuth();

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
      }}
    >
      <Card className={classes.card}>
        <CardMedia
          style={{
            width: "100%",
            backgroundSize: "contain",
            backgroundPosition: "center",
          }}
          image={isDarkMode ? Light : Dark}
          className={classes.media}
        />

        <form className={classes.cardItem}>
          <Stack direction={"column"} gap={1}>
            <DebouncedButton
              startIcon={<FaMicrosoft />}
              size="medium"
              debounceTime={500}
              variant="contained"
              disabled={isLoading}
              loading={isLoading}
              onClick={() => {
                clearAllBrowserStorage();
                void signinRedirect().then(() => {
                  console.log("signed in");
                });
              }}
            >
              Inloggen
            </DebouncedButton>
          </Stack>
        </form>
      </Card>
    </div>
  );
};

export default Login;

function clearAllBrowserStorage() {
  localStorage.clear();
  sessionStorage.clear();
  // Clear all cookies
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  }
}
