import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { SnackbarProvider } from "notistack";
import React, { Suspense, useCallback, useEffect } from "react";
import { AuthProvider, useAuth } from "react-oidc-context";
import { BrowserRouter, useNavigate } from "react-router-dom";
import "./App.css";
// import ReloadPrompt from "./ReloadPrompt";
import Loading from "./components/Loading";
import AuthorizedApolloProvider from "./contexts/AuthorizedApolloProvider";
import useDarkMode from "./hooks/useDarkMode";
import Login from "./pages/Login";
import { dark, light } from "./utils/MuiTheme";
import Routes from "./utils/Routes";
import { enableReactUse } from '@legendapp/state/config/enableReactUse';
enableReactUse() // This adds the use() function to observables


function App() {
  const { isDarkMode } = useDarkMode();
  const navigate = useNavigate();

  const onSigninCallback = useCallback((_user: any) => {
    const user = _user as Root;

    const roles = user.profile.groups;
    if (roles.includes("planner")) {
      navigate("/allteams");
      return;
    }
    if (roles.includes("hulpverlener")) {
      navigate("/caseload");
      return;
    }
    if (roles.includes("team_coordinator")) {
      navigate("/teams");
      return;
    }
    if (roles.includes("read_only")) {
      navigate("/allteams");
      return;
    }
  }, [navigate]);
  const envMode = import.meta.env?.VITE_ENV_VAR ?? ""
  const url = import.meta.url ?? ""

  const oidcConfig = {
    authority: import.meta.env.VITE_KC_AUTHORITY,
    client_id: import.meta.env.VITE_KC_CLIENT_ID,

    redirect_uri: import.meta.env.PROD ? (envMode === "development" && !url.includes("127.0.0.1") && !url.includes("localhost")) ? `http://${url.split("--")[0].replace("https://", "")}--curess-test.netlify.app` : import.meta.env.VITE_KC_REDIRECT_URI : import.meta.env.VITE_KC_REDIRECT_URI,
    onSigninCallback,
  };

  LicenseInfo.setLicenseKey(
    import.meta.env.VITE_MUI_X_PRO
  );
  return (
    <AuthProvider {...oidcConfig}>
      <ThemeProvider theme={isDarkMode ? dark : light}>
        <SnackbarProvider maxSnack={3}>
          <CssBaseline />
          <AuthorizedApolloProvider>
            <Pages />
          </AuthorizedApolloProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;

const Pages = () => {
  const auth = useAuth();
  useEffect(() => {
    return auth.events.addSilentRenewError((e) => {
      throw new Error(`Silent renew error ${e?.message}`);
    })
  }, []);
  return <Suspense fallback={<Loading />}>
    {auth.isAuthenticated ? <Routes /> : <Login />}
    {/* <ReloadPrompt /> */}
  </Suspense>
}


interface Root {
  id_token: string
  session_state: string
  access_token: string
  refresh_token: string
  token_type: string
  scope: string
  profile: Profile
  expires_at: number
}

interface Profile {
  exp: number
  iat: number
  iss: string
  aud: string
  sub: string
  typ: string
  session_state: string
  sid: string
  email_verified: boolean
  name: string
  groups: string[]
  preferred_username: string
  given_name: string
  azure_id: string
  email: string
}

