import { Button } from "@mui/material";
import React from "react";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { enqueueSnackbar } from "notistack";
import { GetInformationForUserQuery, useGetInformationForUserLazyQuery } from "@src/graphql/generated/users.queries.generated";
import * as jwt from 'jsonwebtoken'

type ActionsProps = {};

const privateKey = `
-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA9laxEo6OQ+Ur3suQEcRa
hPa24/V9ZPcP1CBEivy7Bei6gGH5RXTxuLScH1HBvIYc2rHjNH0gn9v+cCDX49H7
d8MzItz89G52HAPr0Ze8k2sS+X7FeQ/QYkFK0/GkKhMer1XPiHNRimyN5KJO/9pI
pLi0w127r90g02CEpGXNDYGSVWVkiHY7oFEEwsXxYLch9o7SQBzpLjMkGuJO1JC4
6aTEq5ThypeNUj2ZkikuI9G/c300mGZ5zhQXyjZY3X6Lz++GDdP/81+/32px9rUU
c9N0qriLrAUDQ+dhgLyfXzYD2v7heZNSt42W0+eDnVsf1Rq6yCBudAvjtpYFRbYs
\nRQIDAQAB
-----END PUBLIC KEY-----
`;

const GetPayload = (data: GetInformationForUserQuery["auth_user_by_pk"]) => {
    if (!data) throw new Error("No data provided");
    const now = Math.floor(Date.now() / 1000);  // Current time in seconds since epoch
    const exp = now + 24 * 60 * 60;  // Expiration time: 24 hours from now

    const { id: userId, email, name, role: defaultRole } = data;

    const payload = {
        "exp": exp,
        "iat": now,
        "auth_time": now,
        "jti": "65f82acc-15c1-4de7-b461-e565aaf73678",
        "iss": "https://keycloak.wearearchitechs.dev/realms/curess",
        "aud": "account",
        "sub": "e15080bb-5b67-448d-818f-2adcb1d0140f",
        "typ": "Bearer",
        "azp": "test-client",
        "session_state": "f845e53a-6667-4838-9726-87df33b88473",
        "acr": "1",
        "allowed-origins": ["*"],
        "scope": "openid email hasura hasura-role-planner profile",
        "sid": "f845e53a-6667-4838-9726-87df33b88473",
        "email_verified": false,
        "https://hasura.io/jwt/claims": {
            "x-hasura-default-role": defaultRole,
            "x-hasura-company-id": "Curess",
            "x-hasura-user-id": userId,
            "x-hasura-allowed-roles": [
                "offline_access",
                "default-roles-curess",
                "uma_authorization",
                defaultRole
            ]
        },
        "name": name,
        "preferred_username": email,
        "given_name": name,
        "email": email
    };

    return payload;
}

const signPayload = (payload: any) => {
    const token = jwt.sign(payload, privateKey, { algorithm: 'RS256' });

    return token;
}

const updateSessionStorage = (token: string) => {
    const key = 'oidc.user:https://keycloak.wearearchitechs.dev/realms/curess:test-client';
    // sessionStorage.setItem(key, JSON.stringify(storedData));
}

const ImpersonateUser: React.FC<ActionsProps> = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [getUserInformationById] = useGetInformationForUserLazyQuery();

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const id = formJson.id;
            const { data } = await getUserInformationById({ variables: { id: id } });
            const payload = GetPayload(data?.auth_user_by_pk);
            const token = signPayload(payload);
        } catch (error) {
            enqueueSnackbar("Error impersonating user", { variant: "error" });
        } finally {
            handleClose();
        }
    }

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
                fullWidth
            >
                Impersonate User
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    component: 'form',
                    onSubmit,
                }}
            >
                <DialogTitle>Impersonate User</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Voer het ID van de user in.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="id"
                        name="id"
                        label="ID"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">Impersonate</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ImpersonateUser;
