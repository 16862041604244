import React from "react";
import Actions from "@src/components/admin/Actions";

export type Job = {
  name: string;
  currentStep: string;
  logs: string[];
  status: "Preparing" | "Running" | "Finished" | "Failed";
};

const Admin = () => {
  return (
    <div>
      <Actions/>
    </div>
  );
};

export default Admin;
