import useSessionStorage from "@src/hooks/useSessionStorage";
import React, { lazy } from "react";
import { hasAuthParams, useAuth } from "react-oidc-context";
import { Navigate, Outlet, useNavigate, useRoutes } from "react-router-dom";
const Login = lazy(() => import("../pages/Login"));
const CaseloadRoom = lazy(() => import("../pages/CaseloadRoom"));
const CaseloadRoomV2 = lazy(() => import("../pages/CaseloadRoomV2"));
const Users = lazy(() => import("../pages/Users"));
const Teams = lazy(() => import("../pages/Teams"));
const Team = lazy(() => import("../pages/Team"));
const Clients = lazy(() => import("../pages/Clients"));
const Caseload = lazy(() => import("../pages/Caseload"));
const WaitingList = lazy(() => import("../pages/WaitingList"));
const AllTeams = lazy(() => import("../pages/AllTeams"));
const CaseLoadReview = lazy(() => import("../components/CaseLoadReview"));
const VervolgcasusTC = lazy(() => import("../pages/VervolgcasusTC"));
const PlannerComments = lazy(() => import("../pages/PlannerComments"));
const SettingsPage = lazy(() => import("../pages/Settings"));
const IncompleteWaitingListPage = lazy(() => import("../pages/IncompleteWaitingList"));
const DataHome = lazy(() => import("../pages/DataHome"));
import Layout from "../components/Layout";
import { AuthGuard as AuthGuard } from "./AuthGuard";
import { format } from "date-fns";
import Admin from "@src/pages/Admin";

const Routes = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  // Automatically redirect to starting page based on role
  React.useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading
    ) {
      navigate("/login");
    }

  }, [
    auth.isAuthenticated,
    auth.activeNavigator,
    auth.isLoading,
    auth.signinRedirect,
  ]);

  React.useEffect(() => {
    // the `return` is important - addAccessTokenExpiring() returns a cleanup function
    return auth.events.addAccessTokenExpiring(() => {
      console.log(`auth.events.addAccessTokenExpiring ${format(new Date(), "dd-MM-yyyy HH:mm")}:`);
      auth.signinSilent();
    })
}, [auth.events, auth.signinSilent])

  let routes = useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard
          roles={["planner", "team_coordinator", "hulpverlener", "read_only"]}
        >
          <Layout />
        </AuthGuard>
      ),
      children: [
        {
          path: "/teams",
          element: (
            <AuthGuard roles={["planner", "team_coordinator", "read_only"]}>
              <Teams />
            </AuthGuard>
          ),
          children: [
            {
              path: ":id",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <Team />,
                },
                {
                  path: "review",
                  element: (
                    <AuthGuard roles={["team_coordinator"]}>
                      <CaseLoadReview />
                    </AuthGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "/teams/:id",
          element: (
            <AuthGuard roles={["planner", "team_coordinator", "read_only"]}>
              <Teams />
            </AuthGuard>
          ),
        },
        {
          path: "/allteams",
          element: (
            <AuthGuard roles={["hulpverlener", "planner", "read_only"]}>
              <AllTeams />
            </AuthGuard>
          ),
        },
        {
          path: "/caseload",
          element: (
            <AuthGuard roles={["hulpverlener"]}>
              <Caseload />
            </AuthGuard>
          ),
        },
        {
          path: "/clienten",
          element: (
            <AuthGuard roles={["planner", "read_only"]}>
              <Clients />
            </AuthGuard>
          ),
        },
        {
          path: "/gebruikers",
          element: (
            <AuthGuard roles={["planner", "read_only"]}>
              <Users />
            </AuthGuard>
          ),
        },
        {
          path: "/caseloadruimte",
          element: (
            <AuthGuard roles={["planner", "read_only"]}>
              <CaseloadRoomV2 />
            </AuthGuard>
          ),
        },
        {
          path: "/wachtlijst",
          element: (
            <AuthGuard roles={["planner"]}>
              <WaitingList />
            </AuthGuard>  
          ),
        },
        {
          path: "/vervolg",
          element: (
            <AuthGuard roles={["team_coordinator"]}>
              <VervolgcasusTC />
            </AuthGuard>  
          ),
        },
        {
          path: "/planner-opmerkingen",
          element: (
            <AuthGuard roles={["team_coordinator"]}>
              <PlannerComments />
            </AuthGuard>  
          ),
        },
        {
          path: "/incomplete-wachtlijst",
          element: (
            <AuthGuard roles={["planner", "read-only", "admin"]}>
              <IncompleteWaitingListPage />
            </AuthGuard>
          )
        },
        {
          path: "/admin",
          element: (
            <AuthGuard roles={["planner"]}>
              <Admin />
            </AuthGuard>
          )
        },
        {
          path: "/settings",
          element: (
            <AuthGuard roles={["planner", "read-only", "admin"]}>
              <SettingsPage />
            </AuthGuard>
          )
        },
        {
          path: "/data",
          element: (
            <AuthGuard roles={["planner", "read-only", "admin"]}>
              <DataHome />
            </AuthGuard>
          )
        }
      ],
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "*",
      element: <Navigate to={"/"} />,
    },
  ]);
  return routes;
};

export default Routes;
