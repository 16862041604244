import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router";

const ProfileButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const {removeUser, user} = useAuth();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutUrl = 'https://keycloak.wearearchitechs.dev/realms/curess/protocol/openid-connect/logout';
  const clientId = 'test-client';
  const refreshToken = user!.refresh_token!;
  const accessToken = user!.access_token!;

  async function keycloakLogout(): Promise<void> {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('Content-Type', 'application/x-www-form-urlencoded');

    const data = new URLSearchParams();
    data.append('client_id', clientId);
    data.append('refresh_token', refreshToken);

    const requestOptions: RequestInit = {
      method: 'POST',
      headers,
      body: data.toString(),
    };

    try {
      const response = await fetch(logoutUrl, requestOptions);
      if (response.ok) {
        console.log('Logout successful');
      } else {
        console.error('Logout failed:', response.statusText);
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }

  function handleLogout() {
    keycloakLogout();
    removeUser();
    navigate("/login");
  }

  return (
    <div>
      <IconButton
        color="inherit"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Avatar alt="Remy Sharp" style={{ width: 24, height: 24 }} />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleLogout}>Uitloggen</MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileButton;
